import type { BackGroundProps } from "./type";
import "./BackgroundForm.scss";

function BackgroundForm(props: BackGroundProps) {
  const { children, url } = props;

  return (
    <div
      className="background-form"
      style={{ background: url ? `url(${url}) 100% 100% / cover` : "#00C8FF" }}
    >
      {children}
    </div>
  );
}

export default BackgroundForm;
