import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumberByRegion = (phone: string, region: string) => {
  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parseAndKeepRawInput(phone, region),
      region
    );
  } catch (error) {
    return false;
  }
};
