import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../formEditInfo.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import * as yup from "yup";
import { useFormik } from "formik";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import { countryApi } from "@/api/countries";
import { ICountry } from "@/shared/ts/type";
import { validateNationality } from "@/shared/utils/validateNationality";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditNationality = React.memo(
  ({ buttonsAction, onCloseModal }: Props) => {
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state: RootState) => state.profile);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [showCountries, setShowCountries] = useState<boolean>(false);
    const [nationalCode, setNationalCode] = useState<string>("");

    const handleGetAllCountries = async () => {
      try {
        const data = await countryApi.getAll();
        setCountries(data);
      } catch (error) {
        return error;
      }
    };

    const handleVisibleCountries = useCallback((show: boolean) => {
      setShowCountries(show);
    }, []);

    useEffect(() => {
      handleGetAllCountries();
    }, []);

    useEffect(() => {
      window.addEventListener("click", () => handleVisibleCountries(false));
      return () => {
        window.removeEventListener("click", () =>
          handleVisibleCountries(false)
        );
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = yup.object({
      nationality: yup.string().required("Nationality is required"),
    });

    const formik = useFormik<{ nationality: string }>({
      initialValues: {
        nationality: profile.nationality,
      },
      validationSchema,
      onSubmit(values, { setFieldError }) {
        // * Check nationality
        const isValidNationality = validateNationality(
          countries,
          values.nationality
        );
        if (!isValidNationality) {
          setFieldError("nationality", "Please select a valid nationality");
          return;
        }
        dispatch(
          changeProfileInfo({
            ...profile,
            ...values,
            nationalityCode: nationalCode,
          })
        );
        onCloseModal();
      },
    });

    const countriesFiltered = useMemo(() => {
      return countries.filter((country) =>
        country.name
          .toLowerCase()
          .includes(formik.values.nationality.toLowerCase())
      );
    }, [formik.values.nationality, countries]);

    const handleChangeNationalCode = useCallback(
      (name: string, code: string) => {
        formik.setFieldValue("nationality", name);
        setNationalCode(code);
      },
      []
    );

    return (
      <form className="form-edit" onSubmit={formik.handleSubmit}>
        <div className="form-edit__group">
          <label className="form-edit__label">NATIONALITY</label>
          <input
            type="text"
            name="nationality"
            value={formik.values.nationality}
            onChange={formik.handleChange}
            className="form-edit__input"
            onClick={(e) => {
              e.stopPropagation();
              handleVisibleCountries(true);
            }}
          />
          {formik.errors.nationality && (
            <p className="form-edit__error--message">
              {formik.errors.nationality}
            </p>
          )}

          {showCountries && (
            <div
              className={`form-edit__country--list ${
                countriesFiltered.length <= 0
                  ? "form-edit__country--list--empty"
                  : ""
              }`}
            >
              {countriesFiltered.length > 0 ? (
                <>
                  {countriesFiltered.map((country) => (
                    <div
                      className="form-edit__country--item"
                      key={country.id}
                      onClick={handleChangeNationalCode.bind(
                        null,
                        country.name,
                        country.two_char_code.toLowerCase()
                      )}
                    >
                      {country.name}
                    </div>
                  ))}
                </>
              ) : (
                <div className="form-edit__country--empty">No options</div>
              )}
            </div>
          )}
        </div>

        {buttonsAction}
      </form>
    );
  }
);

export default FormEditNationality;
