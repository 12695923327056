import { ArrowRightOutlined } from "@ant-design/icons";
import { memo } from "react";
import "./buttonNextStep.scss";
// import IconArrowRight from "@/assets/images/Submit Arrow icon.png";
import IconArrowRight from "@/assets/images/Arrow.png";

type Props = {
  onClick?: () => void;
  title: string;
  className?: string;
  disabled?: boolean;
};

const ButtonNextStep = ({ onClick, className, title, disabled }: Props) => {
  return (
    <button
      type="submit"
      className={`button-next-step ${className} ${
        disabled ? "button-next-step--disabled" : ""
      }`}
      disabled={disabled}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {title} <img src={IconArrowRight} alt="icon-arrow-right" className="button-next-step__icon" />
    </button>
  );
};

export default memo(ButtonNextStep);
