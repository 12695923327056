import React from "react";
import LogoLoading from "@/assets/images/TPM icons-46.png";
import "./loading.scss";
type Props = {};

const Loading = (props: Props) => {
  return (
    <div className="loading">
      <div className="loading__container">
        <img src={LogoLoading} alt="logo-loading" className="loading__logo" />
        <div className="loading__loader"></div>
      </div>
      <p className="loading__text">PLEASE DON’T CLOSE YOUR BROWSER</p>
    </div>
  );
};

export default Loading;
