import React, { useCallback, useEffect, useState } from "react";
import "../formEditInfo.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import * as yup from "yup";
import { useFormik } from "formik";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditAddress = React.memo(({ buttonsAction, onCloseModal }: Props) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const [searchValueAddress, setSearchValueAddress] = useState<string>("");
  const [showListAddress, setShowListAddress] = useState<boolean>(false);

  useEffect(() => {
    if (profile.address) {
      setSearchValueAddress(profile.address);
    }
  }, [profile]);

  const validationSchema = yup.object({
    address: yup.string().required("Address is required"),
  });

  const formik = useFormik<{ address: string }>({
    initialValues: {
      address: profile.address,
    },
    validationSchema,
    onSubmit(values) {
      dispatch(
        changeProfileInfo({
          ...profile,
          ...values,
        })
      );
      onCloseModal();
    },
  });

  const handleSelectAddress = useCallback(
    (address: string) => {
      formik.setFieldValue("address", address);
      setSearchValueAddress(address);
    },
    [formik]
  );
  return (
    <form className="form-edit" onSubmit={formik.handleSubmit}>
      <div className="form-edit__group--address">
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          selectProps={{
            onChange(e) {
              handleSelectAddress(e?.value.description);
            },
            inputValue: searchValueAddress,
            onInputChange(newValue, actionMeta) {
              switch (actionMeta.action) {
                case "input-change":
                  setSearchValueAddress(newValue);
                  break;
              }
              setShowListAddress(true);
            },
            value: {
              label: searchValueAddress,
              value: {
                description: searchValueAddress,
              },
            },
            classNamePrefix: `form-edit__select-address`,
            classNames: {
              menu() {
                return `form-edit__select--menu" ${
                  !showListAddress ? "hidden" : ""
                }`;
              },
            },
            onFocus() {
              setShowListAddress(false);
            },
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: [`au`],
            },
          }}
        />
      </div>

      {buttonsAction}
    </form>
  );
});

export default FormEditAddress;
