import IconChecked from "@/assets/images/TPM assets Icons-41.png";
import IconPlus from "@/assets/images/TPM assets Icons-45.png";
import IconArrowDown from "@/assets/images/TPM icons-48.png";
import "./identityPlaceholder.scss";

type Props = {
  isFinishInfo: boolean;
  visibleForm: boolean;
  handleVisibleForm: () => void;
  title: string;
  icon: string;
};

const IdentityPlaceholder = ({
  title,
  icon,
  isFinishInfo,
  visibleForm,
  handleVisibleForm,
}: Props) => {
  return (
    <div
      className={`identity-placeholder ${
        visibleForm ? "identity-placeholder__open" : ""
      } `}
      onClick={handleVisibleForm}
    >
      <div className="identity-placeholder__content">
        <img
          src={icon}
          alt="icon-driver-license"
          loading="lazy"
          className="identity-placeholder__img"
        />
        <p className="identity-placeholder__title">
          {title}
          <img
            src={IconArrowDown}
            alt="icon-arrow-down"
            className="identity-placeholder__icon--arrow"
          />
        </p>
      </div>
      {isFinishInfo ? (
        <img
          src={IconChecked}
          alt="icon-checked"
          className="identity-placeholder__icon"
        />
      ) : (
        <img
          src={IconPlus}
          alt="icon-plus"
          className="identity-placeholder__icon"
        />
      )}
    </div>
  );
};

export default IdentityPlaceholder;
