import React, { useCallback, useEffect, useState } from "react";
import "../formEditInfo.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import * as yup from "yup";
import { useFormik } from "formik";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import {
  formatAustralianPhoneNumber,
  validatePhoneNumberByRegion,
} from "@/shared/utils";
import { patternNumberKeyboard, regexPhoneNumberAustralian } from "@/shared/regex";
import { checkPhoneNumber } from "@/api/checkPhoneNumber";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditMobileNumber = React.memo(
  ({ buttonsAction, onCloseModal }: Props) => {
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state: RootState) => state.profile);
    const [phoneFormatted, setPhoneFormatted] = useState<string>("");
    const [errorsPhoneNumber, setErrorsPhoneNumber] = useState<string[]>([]);

    const validationSchema = yup.object({
      mobileNumber: yup
        .string()
        .required("Mobile number is required")
        .matches(regexPhoneNumberAustralian, "Mobile number invalid"),
    });

    const formik = useFormik<{ mobileNumber: string }>({
      initialValues: {
        mobileNumber: profile.mobileNumber,
      },
      validationSchema,
      async onSubmit(values, { setFieldError }) {
        try {
          const isPhoneValid = validatePhoneNumberByRegion(
            values.mobileNumber,
            "AU"
          );
          if (!isPhoneValid) {
            setFieldError("mobileNumber", "Phone number invalid");
            return;
          }
          await checkPhoneNumber(values.mobileNumber);

          dispatch(
            changeProfileInfo({
              ...profile,
              ...values,
            })
          );
          setErrorsPhoneNumber([]);
          onCloseModal();
        } catch (error: any) {
          setErrorsPhoneNumber(error.response.data.error.mobile);
        }
      },
    });

    useEffect(() => {
      if (profile.mobileNumber) {
        const newValue = formatAustralianPhoneNumber(
          profile.mobileNumber,
          true
        );
        setPhoneFormatted(newValue);
      }
    }, [profile]);

    const handleChangeMobileNumber = useCallback(
      (value: string) => {
        const formattedNumber = formatAustralianPhoneNumber(value);
        setPhoneFormatted(formattedNumber);

        if (value.startsWith("+")) {
          const newValue = formattedNumber.split("+")[1];
          if (newValue) {
            formik.setFieldValue("mobileNumber", newValue.split(" ").join(""));
          } else {
            formik.setFieldValue("mobileNumber", value);
          }
          return;
        }

        formik.setFieldValue(
          "mobileNumber",
          formattedNumber.split(" ").join("")
        );
      },
      [formik]
    );
    return (
      <form className="form-edit" onSubmit={formik.handleSubmit} noValidate>
        <div className="form-edit__group">
          <label className="form-edit__label">MOBILE NUMBER</label>
          <input
            type="text"
            name="mobileNumber"
            value={phoneFormatted}
            onChange={(e) => handleChangeMobileNumber(e.target.value)}
            className="form-edit__input"
            pattern={patternNumberKeyboard}
          />
          {formik.errors.mobileNumber && (
            <p className="form-edit__error--message">
              {formik.errors.mobileNumber}
            </p>
          )}
          {errorsPhoneNumber?.length > 0 && (
            <>
              {errorsPhoneNumber.map((err, index) => (
                <p className="form-edit__error--message" key={index}>
                  {err}
                </p>
              ))}
            </>
          )}
        </div>

        {buttonsAction}
      </form>
    );
  }
);

export default FormEditMobileNumber;
