import { Image, Input } from "antd";
import { memo } from "react";
import { ICurrency, IError } from "../../shared/ts/type";
import "./CurrencyPriceCard.scss";

type Props = {
  currency: ICurrency;
  destinationCurrency: ICurrency;
  value: string;
  rate: string;
  isShowRate: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setErrorExChange?: React.Dispatch<React.SetStateAction<IError>>;
  indexConverter: number;
};
function CurrencyPriceCard({
  currency,
  value,
  onChange,
  rate,
  isShowRate,
}: Props) {
  return (
    <div className="currency-price-card">
      <div className="currency-price-card-header">
        <Image
          className="emoji"
          src={currency.icon}
          width={30}
          preview={false}
        />
        {currency.currency_code}
      </div>
      <div className="currency-price-card-content">
        <Input
          type="number"
          min="0"
          value={value}
          onChange={onChange}
        />

        {isShowRate && <p className="rate m-0">Rate: {rate}</p>}
      </div>
    </div>
  );
}
export default memo(CurrencyPriceCard);
