import apiClient from "./apiClient";
import { IPayloadVerifyIdentity } from "./interface/verifyIdentity.interface";

export const verifyIdentity = async (payload: IPayloadVerifyIdentity) => {
  try {
    const { data } = await apiClient.post("/travelpay/onboarding", {
      payload: payload,
    });
    if (data.error) {
      const error= data.referenceNumber
      localStorage.setItem(
        "error",
        JSON.stringify({
          error
        })
      );
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    throw error;
  }
};
