export interface ICurrency {
  icon: string;
  currency_code: string;
  currency_symbol: string;
  name: string;
  value?: string;
}

export interface IAddressPlace {
  description: string;
  place_id: string;
}

export interface ICountry {
  id: number;
  name: string;
  two_char_code: string;
  three_char_code: string;
  iso_no: string;
  emoji: string;
}

export interface IError {
  message: string;
  status: string;
  stack: string;
}

export enum EStatusPayment {
  Success = "Successful",
  Fail = "Failure",
}

export enum EStatusKYCOnboarding {
  Success = "success",
  Pending = "pending",
  Fail = "fail",
}
