import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import "./buttonBackStep.scss";
type Props = {
  onClick: () => void;
  className?: string;
};

const ButtonBackStep = ({ onClick, className }: Props) => {
  return (
    <button
      type="button"
      className={`button-back-step ${className}`}
      onClick={onClick}
    >
      <LeftOutlined color="#30375e" />
      <span className="button-back-step__text">Back</span>
    </button>
  );
};

export default ButtonBackStep;
