import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProfile } from "./interface";

interface IInitialState {
  profile: IProfile;
}

const initialState: IInitialState = {
  profile: {
    firstName: "",
    lastName: "",
    email: "",
    middleName: "",
    gender: "",
    mobileNumber: "",
    dateOfBirth: "",
    address: "",
    addressState: {
      addressLine: "",
      townCity: "",
      state: "",
      country: "",
      postcode: "",
    },
    nationality: "",
    nationalityCode: "",
    agreePolicy: false,
    identificationDoc: {
      driverLicense: {
        number: undefined,
        stateIssued: {
          authority: "",
          shortname: "",
        },
        expireDate: "",
      },
      passport: {
        number: "",
        issuedBy: "",
        expireDate: "",
        issuedCode: "",
      },
      medicare: {
        number: "",
        referenceNumber: undefined,
        expireDate: "",
      },
    },
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    changeProfileInfo: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
  },
});

export const { changeProfileInfo } = profileSlice.actions;
export default profileSlice.reducer;
