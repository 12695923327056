export const regexPhoneNumberAustralian =
  /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/gm;
export const formatPhoneAUDWithZero = /^(0\d{1})(\d{4})(\d{4})$/;
export const formatPhoneAUD = /^(\d{2})(\d{4})(\d{4})$/;
export const formatPhoneAUDWithSpacing = /(\d{2})(\d{4})(\d{4})/;
export const formatPhoneAUDWithSpacingZero = /(\d{4})(\d{3})(\d{3})/;

export const regexAustraliaDriverLicenseNumber = /\d{10,11}/;
export const regexPassportNumber = /^[A-Z][0-9]{8}$"/;
export const regexMedicareNumber = /\d{9,10}/;
export const regexAllowOnlyNumber = /[^0-9]/g;
export const regexDigitMonth = /^([2-9]|1[3-9])/g;
export const regexLeadZeroNumber = /^0{1,}/g;
export const regexFormatMonthYear = /^([0-1][0-9])([0-9]{1,4}).*/g;

export const patternNumberKeyboard = "[0-9]*";
