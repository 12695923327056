import ConfirmDetailActiveIcon from "@/assets/images/step-check-active.png";
import ConfirmDetailIcon from "@/assets/images/step-check.png";
import TakeOffActiveIcon from "@/assets/images/step-plane-active.png";
import TakeOffIcon from "@/assets/images/step-plane.png";
import AboutYouIcon from "@/assets/images/step-user.png";
import AboutYouActiveIcon from "@/assets/images/step-user-active.png";
import { Image, Steps } from "antd";

import { searchParamsKeys } from "@/shared/constants";
import { EStatusKYCOnboarding } from "@/shared/ts/type";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import "./StepsBoarding.scss";

interface StepsProps {
  view: number;
}

function StepsBoarding(props: StepsProps) {
  const view = Number(props.view);
  const [searchParams] = useSearchParams();
  const statusKYC = searchParams.get(searchParamsKeys.kycStatus);

  const isFailKYC = useMemo(() => {
    if (!statusKYC) return true;
    return (
      statusKYC !== EStatusKYCOnboarding.Success &&
      statusKYC !== EStatusKYCOnboarding.Pending
    );
  }, [statusKYC]);

  if (view === 4) return <></>;

  return (
    <div className="steps-boarding">
      <div className="steps-boarding__container">
        <div className="steps-boarding-title">
          PACK <span>LIGHT</span>,PAY<span> SMART</span>
        </div>
        <div className="steps-wrap">
          <Steps
            size="small"
            labelPlacement="vertical"
            current={view - 1}
            responsive={false}
            items={[
              {
                title: (
                  <div
                    className="steps-custom-title"
                    style={{ opacity: 1, fontWeight: view < 2 ? 500 : 700 }}
                  >
                    <span>About you</span>{" "}
                    <Image
                      preview={false}
                      src={view >= 2 ? AboutYouActiveIcon : AboutYouIcon}
                      style={{ opacity: 1 }}
                      rootClassName="steps-onboarding__icon"
                    />
                  </div>
                ),
              },
              {
                title: (
                  <div
                    className="steps-custom-title"
                    style={{ fontWeight: view < 3 ? 500 : 700 }}
                  >
                    <span>Confirm Details</span>
                    <Image
                      preview={false}
                      src={
                        view >= 3 ? ConfirmDetailActiveIcon : ConfirmDetailIcon
                      }
                      rootClassName="steps-onboarding__icon"
                    />
                  </div>
                ),
              },
              {
                title: (
                  <div
                    className="steps-custom-title"
                    style={{ fontWeight: view < 4 ? 500 : 700 }}
                  >
                    <span>Take Off!</span>
                    <Image
                      preview={false}
                      src={view >= 4 ? TakeOffActiveIcon : TakeOffIcon}
                      rootClassName="steps-onboarding__icon"
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
export default StepsBoarding;
