import { countryApi } from "@/api/countries";
import cardGraphicImage from "@/assets/images/card-graphic.png";
import { BackgroundForm, StartedForm, StepsBoarding } from "@/components";
import AboutYou from "@/components/AboutYou";
import ConfirmYourDetails from "@/components/ConfirmYourDetails";
import Footer from "@/components/Footer";
import { Header } from "@/components/Header";
import KycLastScreen from "@/components/KycLastScreen";
import VerifyIdentity from "@/components/VerifyIdentity";
import { ICountry } from "@/shared/ts/type";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  createSearchParams,
  useLocation,
  useNavigate
} from "react-router-dom";
import "./onBoarding.scss";

function OnBoarding() {
  const lastScreen = 4;
  const [view, setView] = useState<number>(0);
  const location = useLocation()
  const navigate = useNavigate()
  const [countries, setCountries] = useState<ICountry[]>([]);
  const handleGetAllCountries = async () => {
    try {
      const data = await countryApi.getAll();
      setCountries(data);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({}).toString(),
    });
    handleGetAllCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    switch (view) {
      case 1:
        return (
          <AboutYou
            setView={setView}
            countries={countries}
            setCountries={setCountries}
          />
        );
      case 2:
        return <ConfirmYourDetails setView={setView} />;
      case 3:
        return <VerifyIdentity setView={setView} />;
      case 4:
        return <KycLastScreen />;
      default:
        return <StartedForm setView={setView} />;
    }
  };

  return (
    <div className="onboarding">
      <Helmet>
        <link
          rel="stylesheet"
          href={process.env.REACT_APP_ZENPAY_PAYMENT_CSS ?? ""}
        />
        <link
          rel="stylesheet"
          href={process.env.REACT_APP_ZENPAY_BOOSTRAP_CSS ?? ""}
        />
        <script src={process.env.REACT_APP_ZENPAY_PAYMENT_JS ?? ""}></script>
        <script src={process.env.REACT_APP_ZENPAY_BOOTSTRAP_JS ?? ""}></script>
      </Helmet>
      <Header />
      <div className="onboarding-body">
        <StepsBoarding view={view} />
        <div className="container-fluid onboarding__content">
          <div className="row">
            <div
              className={`${
                view === lastScreen ? "w-100 p-0" : "col-lg-6 p-0"
              } `}
            >
              {renderContent()}
            </div>
            {view !== lastScreen && (
              <div className="col-lg-6 p-0 onboarding__form--section">
                <BackgroundForm>
                  <img
                    src={cardGraphicImage}
                    alt=""
                    className="onboarding__banner--right"
                  />
                </BackgroundForm>
              </div>
            )}
          </div>
        </div>
        <Footer isLastScreen={view === lastScreen} />
      </div>
    </div>
  );
}

export default OnBoarding;
