import {
  formatPhoneAUD,
  formatPhoneAUDWithZero,
  formatPhoneAUDWithSpacing,
  formatPhoneAUDWithSpacingZero,
} from "../regex";

export const formatAustralianPhoneNumber = (
  phoneNumber: string,
  isCleanText?: boolean
) => {
  // * For need clear spacing in text
  if (!isCleanText) {
    let cleaned = ("" + phoneNumber).replace(/\D/g, "");
    let match = cleaned.match(formatPhoneAUDWithZero);
    if (match) {
      return phoneNumber.replace(formatPhoneAUDWithSpacingZero, "$1 $2 $3");
    } else {
      match = cleaned.match(formatPhoneAUD);
      if (match) {
        return `+${match[1]} ${match[2]} ${match[3]}`;
      }
    }
    return phoneNumber;
  }

  // * For don't need clear spacing in text
  if (phoneNumber.startsWith("0")) {
    return phoneNumber.replace(formatPhoneAUDWithSpacingZero, "$1 $2 $3");
  }
  return phoneNumber.replace(formatPhoneAUDWithSpacing, "+$1 $2 $3");
};
