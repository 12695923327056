import { useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import { formatAustralianPhoneNumber } from "@/shared/utils";
import React, { useCallback, useMemo, useState } from "react";
import ButtonBackStep from "../ButtonBackStep";
import ButtonNextStep from "../ButtonNextStep";
import { HeaderFormView } from "../HeaderFormView";
import ModalEditInfo from "./components/ModalEditInfo";
import { ETypeInfo } from "./components/ModalEditInfo/ModalEditInfo";
import "./confirmYourDetails.scss";

type Props = {
  setView: React.Dispatch<React.SetStateAction<number>>;
};

const ConfirmYourDetails = React.memo(({ setView }: Props) => {
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const [showModalEditInfos, setShowModalEditInfos] = useState<{
    [ETypeInfo.FullName]: boolean;
    [ETypeInfo.Email]: boolean;
    [ETypeInfo.DateOfBirth]: boolean;
    [ETypeInfo.MobileNumber]: boolean;
    [ETypeInfo.Nationality]: boolean;
    [ETypeInfo.Address]: boolean;
  }>({
    [ETypeInfo.FullName]: false,
    [ETypeInfo.Email]: false,
    [ETypeInfo.DateOfBirth]: false,
    [ETypeInfo.MobileNumber]: false,
    [ETypeInfo.Nationality]: false,
    [ETypeInfo.Address]: false,
  });

  const mobileNumber = useMemo(() => {
    return formatAustralianPhoneNumber(profile.mobileNumber, true);
  }, [profile.mobileNumber]);

  const handleShowModalEditInfo = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, type: ETypeInfo) => {
      e.stopPropagation();
      setShowModalEditInfos({ ...showModalEditInfos, [type]: true });
    },
    [showModalEditInfos]
  );

  const handleCloseModalEditInfo = useCallback(
    (type: ETypeInfo) => {
      setShowModalEditInfos({ ...showModalEditInfos, [type]: false });
    },
    [showModalEditInfos]
  );

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      const {
        address,
        dateOfBirth,
        email,
        firstName,
        mobileNumber,
        nationality,
      } = profile;
      if (
        !address ||
        !dateOfBirth ||
        !email ||
        !firstName ||
        !mobileNumber ||
        !nationality
      )
        return;
      setView(3);
    },
    [profile, setView]
  );

  const formatDateValue = useMemo(() => {
    if (!profile.dateOfBirth) return "";
    const [year, month, day] = profile.dateOfBirth.split("-");
    return `${month}/${day}/${year}`;
  }, [profile]);
  return (
    <div className="confirm-details">
      <div className="confirm-details__container">
        <ButtonBackStep
          className="confirm-details__btn--back"
          onClick={() => setView((prev) => prev - 1)}
        />
        <HeaderFormView
          title="IS EVERYTHING CORRECT?"
          subtitle="Double-check now to avoid delays in setting up your TravelPay Money Card."
        />
        <form className="confirm-details__form" onClick={handleSubmit}>
          {/* ====== NAME ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">NAME</label>
            <div className="confirm-details__form--input--group">
              <input
                value={`${profile?.firstName} ${profile.middleName} ${profile?.lastName}`}
                type="text"
                className="confirm-details__form--input"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) => handleShowModalEditInfo(e, ETypeInfo.FullName)}
              >
                Update
              </div>
            </div>
          </div>

          {/* ====== EMAIL ADDRESS ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">
              EMAIL ADDRESS
            </label>
            <div className="confirm-details__form--input--group">
              <input
                type="text"
                value={profile.email}
                className="confirm-details__form--input"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) => handleShowModalEditInfo(e, ETypeInfo.Email)}
              >
                Update
              </div>
            </div>
          </div>

          {/* ====== DATE OF BIRTH ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">
              DATE OF BIRTH
            </label>
            <div className="confirm-details__form--input--group--date">
              <input
                value={formatDateValue}
                type="text"
                className="confirm-details__form--input--date"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) =>
                  handleShowModalEditInfo(e, ETypeInfo.DateOfBirth)
                }
              >
                Update
              </div>
            </div>
          </div>

          {/* ====== MOBILE NUMBER ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">
              MOBILE NUMBER
            </label>
            <div className="confirm-details__form--input--group">
              <input
                value={mobileNumber}
                type="text"
                className="confirm-details__form--input"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) =>
                  handleShowModalEditInfo(e, ETypeInfo.MobileNumber)
                }
              >
                Update
              </div>
            </div>
          </div>

          {/* ====== NATIONALITY ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">NATIONALITY</label>
            <div className="confirm-details__form--input--group">
              <input
                value={profile.nationality}
                type="text"
                className="confirm-details__form--input"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) =>
                  handleShowModalEditInfo(e, ETypeInfo.Nationality)
                }
              >
                Update
              </div>
            </div>
          </div>

          {/* ====== ADDRESS ====== */}
          <div className="confirm-details__form--group">
            <label className="confirm-details__form--label">ADDRESS</label>
            <div className="confirm-details__form--input--group">
              <input
                value={profile.address}
                type="text"
                className="confirm-details__form--input confirm-details__form--input--ellipsis"
                disabled
              />
              <div
                className="confirm-details__form--btn--update"
                onClick={(e) => handleShowModalEditInfo(e, ETypeInfo.Address)}
              >
                Update
              </div>
            </div>
          </div>

          <ButtonNextStep
            title="CONFIRM"
            className="confirm-details__form--btn--submit"
          />
        </form>

        {/* ==== MODAL FULL NAME ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.FullName]}
          title="FULL NAME"
          type={ETypeInfo.FullName}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.FullName)}
        />

        {/* ==== MODAL EMAIL ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.Email]}
          title="EMAIL ADDRESS"
          type={ETypeInfo.Email}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.Email)}
        />
        {/* ==== MODAL DATE OF BIRTH ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.DateOfBirth]}
          title="DATE OF BIRTH"
          type={ETypeInfo.DateOfBirth}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.DateOfBirth)}
        />
        {/* ==== MODAL MOBILE NUMBER ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.MobileNumber]}
          title="MOBILE NUMBER"
          type={ETypeInfo.MobileNumber}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.MobileNumber)}
        />
        {/* ==== MODAL NATIONALITY ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.Nationality]}
          title="NATIONALITY"
          type={ETypeInfo.Nationality}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.Nationality)}
        />

        {/* ==== MODAL ADDRESS ===== */}
        <ModalEditInfo
          isOpen={showModalEditInfos[ETypeInfo.Address]}
          title="COMPLETE ADDRESS"
          type={ETypeInfo.Address}
          onClose={handleCloseModalEditInfo.bind(null, ETypeInfo.Address)}
        />
      </div>
    </div>
  );
});

export default ConfirmYourDetails;
