import React from "react";
import "./footer.scss";
import { nanoid } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ImgFooter from "@/assets/images/icons and logo footer.png";

type Props = {
  isLastScreen?: boolean;
};

const Footer = ({ isLastScreen }: Props) => {
  const logoFooterList: { id: string; image: string; className?: string }[] = [
    {
      id: nanoid(),
      image: "/Footer 1.png",
    },
    {
      id: nanoid(),
      image: "/Footer 2.png",
      className: "footer__logo--item--img--visa",
    },
    {
      id: nanoid(),
      image: "/Footer 3.png",
    },
    {
      id: nanoid(),
      image: "/Footer 4.png",
    },
  ];
  return (
    <footer
      className={clsx("footer", isLastScreen ? "footer__last--screen" : "")}
    >
      <p className="footer__text">
        The Visa Pelikin Travel Card is issued by Nium, subject to license from
        Visa Worldwide <br />{" "}
        <Link
          to="https://pelikin.co/pds.pdf"
          target="_blank"
          className="footer__text--link"
        >
          Product Disclosure Agreement
        </Link>{" "}
        |{" "}
        <Link
          to="https://pelikin.co/fsg.pdf"
          target="_blank"
          className="footer__text--link"
        >
          Financial Services Guide
        </Link>{" "}
        |{" "}
        <Link
          to="https://pelikin.co/targetmarketdetermination.pdf"
          target="_blank"
          className="footer__text--link"
        >
          Target Market Determination
        </Link>{" "}
        |{" "}
        <Link
          to="https://pelikin.co/termsandconditions.pdf"
          target="_blank"
          className="footer__text--link"
        >
          Terms & Conditions
        </Link>{" "}
        |{" "}
        <Link
          to="https://pelikin.co/privacypolicy.pdf"
          target="_blank"
          className="footer__text--link"
        >
          Privacy Policy
        </Link>{" "}
        <br />
        Pelikin Holdings Ltd | ACN: 161348983 | ® Pelikin 2024
      </p>
      <div className="footer__logo--list">
        {isLastScreen ? (
          <div>
            <img
              src={ImgFooter}
              alt="footer last screen"
              className={`footer__logo--item--last`}
            />
          </div>
        ) : (
          logoFooterList.map((logo) => (
            <div className="footer__logo--item" key={logo.id}>
              <img
                src={logo.image}
                alt=""
                className={`footer__logo--item--img ${logo.className}`}
              />
            </div>
          ))
        )}
      </div>
    </footer>
  );
};

export default Footer;
