import IconDriverLicense from "@/assets/images/TPM Icons-42.png";
import IconArrowDown from "@/assets/images/TPM icons-47.png";
import { IIdentity } from "@/redux/profile/interface";
import { Select } from "antd";
import { FormikProps } from "formik";
import { DefaultOptionType } from "rc-select/lib/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import IdentityPlaceholder from "../IdentityPlaceholder";

import { useAppDispatch, useAppSelector } from "@/redux/hook";
import "./driverLicense.scss";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import { RootState } from "@/redux/store";
import { patternNumberKeyboard } from "@/shared/regex";
type Props = {
  formik: FormikProps<IIdentity>;
};

const DriverLicense = React.memo(({ formik }: Props) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const optionsStateIssued: DefaultOptionType[] = [
    {
      value: "Australian Capital Territory",
      label: "ACT",
    },
    {
      value: "New South Wales",
      label: "NSW",
    },
    {
      value: "Northern Territory",
      label: "NT",
    },
    {
      value: "Queensland",
      label: "QLD",
    },
    {
      value: "South Australia",
      label: "SA",
    },
    {
      value: "Tasmania",
      label: "TAS",
    },
    {
      value: "Victoria",
      label: "VIC",
    },
    {
      value: "Western Australia",
      label: "WA",
    },
  ];

  useEffect(() => {
    const { driverLicense } = formik.values;
    if (
      driverLicense.number &&
      driverLicense.expireDate &&
      driverLicense.stateIssued
    ) {
      dispatch(
        changeProfileInfo({
          ...profile,
          identificationDoc: {
            ...profile.identificationDoc,
            driverLicense: {
              ...driverLicense,
            },
          },
        })
      );
    }
  }, [dispatch, formik.values]);

  const handleVisibleForm = useCallback(() => {
    setOpenForm(!openForm);
  }, [openForm]);

  const isFinishInfo = useMemo(() => {
    const { number, expireDate, stateIssued } = formik.values.driverLicense;
    return Boolean(
      number &&
        expireDate &&
        stateIssued.authority &&
        !formik.errors.driverLicense?.expireDate
    );
  }, [formik]);

  const handleChangeExpireDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      const previousValue = formik.values.driverLicense.expireDate || "";

      // Replace all non-numeric characters except slashes
      value = value.replace(/[^0-9/]/g, "");

      // Check if the user is deleting a character
      if (previousValue.length > value.length) {
        // Handle deletion near slashes
        if (previousValue.length === 4 && value.length === 3) {
          value = value.slice(0, 2);
        } else if (previousValue.length === 7 && value.length === 6) {
          value = value.slice(0, 5);
        }
      } else {
        // Add slashes for date format
        if (value.length > 2 && value[2] !== "/") {
          value = value.slice(0, 2) + "/" + value.slice(2);
        }
        if (value.length > 5 && value[5] !== "/") {
          value = value.slice(0, 5) + "/" + value.slice(5);
        }
      }

      formik.setFieldValue("driverLicense.expireDate", value);
    },
    [formik]
  );

  return (
    <div className={`driver-license ${openForm ? "open" : ""}`}>
      <IdentityPlaceholder
        title="Australian Driver’s License"
        visibleForm={openForm}
        handleVisibleForm={handleVisibleForm}
        isFinishInfo={isFinishInfo}
        icon={IconDriverLicense}
      />

      {/* ==== FORM ==== */}
      {openForm && (
        <div className="driver-license__form">
          {/* ==== NUMBER ==== */}
          <div className="driver-license__form--group">
            <div className="driver-license__form--label">LICENSE NUMBER</div>
            <input
              type="text"
              name="driverLicense.number"
              value={formik.values.driverLicense.number ?? ""}
              onChange={formik.handleChange}
              className={`driver-license__form--input driver-license__form--input--number ${
                formik.errors.driverLicense?.number
                  ? "driver-license__form--input--error"
                  : ""
              }`}
              placeholder="0000 0000 0000"
            />
            {formik.errors.driverLicense?.number && (
              <p className="driver-license__form--error">
                {formik.errors.driverLicense.number}
              </p>
            )}
          </div>

          {/* ==== STATE ISSUED ==== */}
          <div className="driver-license__form--group">
            <div className="driver-license__form--label">STATE ISSUED</div>
            <Select
              style={{ width: "90px" }}
              value={formik.values.driverLicense.stateIssued.shortname}
              onChange={(value, option) => {
                const newValue = option as DefaultOptionType;
                formik.setFieldValue("driverLicense.stateIssued", {
                  shortname: newValue.label,
                  authority: newValue.value,
                });
              }}
              prefixCls={`driver-license__form--input--states`}
              className={`${
                formik.errors.driverLicense?.stateIssued
                  ? "driver-license__form--input--error"
                  : ""
              }`}
              options={optionsStateIssued}
              suffixIcon={
                <img
                  src={IconArrowDown}
                  alt="icon-arrow-down"
                  className="driver-license__form--select-arrow"
                />
              }
            />
            {formik.errors.driverLicense?.stateIssued && (
              <p className="driver-license__form--error">
                {formik.errors.driverLicense.stateIssued.authority}
              </p>
            )}
          </div>

          {/* ==== EXPIRY DATE ==== */}
          <div className="driver-license__form--group">
            <div className="driver-license__form--label">EXPIRY DATE</div>
            <input
              type="text"
              name="driverLicense.expireDate"
              value={formik.values.driverLicense.expireDate}
              onChange={handleChangeExpireDate}
              className={`driver-license__form--input driver-license__form--input--date ${
                formik.errors.driverLicense?.expireDate
                  ? "driver-license__form--input--error"
                  : ""
              }`}
              pattern={patternNumberKeyboard}
              placeholder="DD/MM/YYYY"
            />
            {formik.touched.driverLicense?.expireDate &&
              formik.errors.driverLicense?.expireDate && (
                <p className="driver-license__form--error">
                  {formik.errors.driverLicense.expireDate}
                </p>
              )}
          </div>
        </div>
      )}
    </div>
  );
});

export default DriverLicense;
