import { Navigate, Route, Routes } from "react-router-dom";
import OnBoarding from "./pages/OnBoarding/OnBoarding";
import { routerNames } from "./shared/constants/routerNames";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/partner1"} replace />} />
      <Route path={routerNames.partnerDetail} element={<OnBoarding />} />
    </Routes>
  );
};
