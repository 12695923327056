import React, { useState } from "react";
import "../formEditInfo.scss";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import * as yup from "yup";
import { checkEmail } from "@/api/checkEmail";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditEmail = React.memo(({ buttonsAction, onCloseModal }: Props) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const [errorsEmail, setErrorsEmail] = useState<string[]>([]);
  const validationSchema = yup.object({
    email: yup.string().required("Please enter your email address"),
  });

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: profile.email,
    },
    validationSchema,
    async onSubmit(values) {
      try {
        await checkEmail(values.email);
        dispatch(
          changeProfileInfo({
            ...profile,
            ...values,
          })
        );
        onCloseModal();
        setErrorsEmail([]);
      } catch (error: any) {
        setErrorsEmail(error.response?.data?.error?.email);
      }
    },
  });
  return (
    <form className="form-edit" onSubmit={formik.handleSubmit}>
      <div className="form-edit__group">
        <label className="form-edit__label">EMAIL ADDRESS</label>
        <input
          type="text"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          className="form-edit__input"
        />
        {formik.errors.email && (
          <p className="form-edit__error--message">{formik.errors.email}</p>
        )}
        {errorsEmail?.length > 0 && (
          <>
            {errorsEmail.map((err, index) => (
              <p className="form-edit__error--message" key={index}>
                {err}
              </p>
            ))}
          </>
        )}
      </div>

      {buttonsAction}
    </form>
  );
});

export default FormEditEmail;
