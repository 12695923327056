import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./passport.scss";
import IdentityPlaceholder from "../IdentityPlaceholder";
import { FormikProps } from "formik";
import { IIdentity } from "@/redux/profile/interface";
import IconPassPort from "@/assets/images/TPM Icons-43.png";
import { countryApi } from "@/api/countries";
import { ICountry } from "@/shared/ts/type";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import { patternNumberKeyboard } from "@/shared/regex";

type Props = {
  formik: FormikProps<IIdentity>;
};

const Passport = ({ formik }: Props) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [showCountries, setShowCountries] = useState<boolean>(false);

  const [countries, setCountries] = useState<ICountry[]>([]);

  const handleGetAllCountries = async () => {
    try {
      const data = await countryApi.getAll();
      setCountries(data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleGetAllCountries();
  }, []);

  useEffect(() => {
    const { passport } = formik.values;

    if (passport.number && passport.expireDate && passport.issuedBy) {
      dispatch(
        changeProfileInfo({
          ...profile,
          identificationDoc: {
            ...profile.identificationDoc,
            passport: {
              ...passport,
            },
          },
        })
      );
    }
  }, [dispatch, formik.values]);

  const handleVisibleForm = useCallback(() => {
    setOpenForm(!openForm);
  }, [openForm]);

  const isFinishInfo = useMemo(() => {
    const { number, expireDate, issuedBy } = formik.values.passport;
    return Boolean(
      number && expireDate && issuedBy && !formik.errors.passport?.expireDate
    );
  }, [formik]);

  const handleVisibleCountries = useCallback((show: boolean) => {
    setShowCountries(show);
  }, []);

  useEffect(() => {
    window.addEventListener("click", () => handleVisibleCountries(false));
    return () => {
      window.removeEventListener("click", () => handleVisibleCountries(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countriesFiltered = useMemo(() => {
    return countries.filter((country) =>
      country.name
        .toLowerCase()
        .includes(formik.values.passport.issuedBy.toLowerCase())
    );
  }, [formik.values.passport.issuedBy, countries]);

  const handleChangeIssueBy = useCallback(
    (name: string, code: string) => {
      formik.setFieldValue("passport.issuedBy", name);
      formik.setFieldValue("passport.issuedCode", code);
    },
    [formik]
  );

  const handleChangeExpireDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      const previousValue = formik.values.passport.expireDate || "";

      // Replace all non-numeric characters except slashes
      value = value.replace(/[^0-9/]/g, "");

      // Check if the user is deleting a character
      if (previousValue.length > value.length) {
        // Handle deletion near slashes
        if (previousValue.length === 4 && value.length === 3) {
          value = value.slice(0, 2);
        } else if (previousValue.length === 7 && value.length === 6) {
          value = value.slice(0, 5);
        }
      } else {
        // Add slashes for date format
        if (value.length > 2 && value[2] !== "/") {
          value = value.slice(0, 2) + "/" + value.slice(2);
        }
        if (value.length > 5 && value[5] !== "/") {
          value = value.slice(0, 5) + "/" + value.slice(5);
        }
      }

      formik.setFieldValue("passport.expireDate", value);
    },
    [formik]
  );

  return (
    <div className={`passport ${openForm ? "open" : ""}`}>
      <IdentityPlaceholder
        title="Passport"
        visibleForm={openForm}
        handleVisibleForm={handleVisibleForm}
        isFinishInfo={isFinishInfo}
        icon={IconPassPort}
      />

      {/* ==== FORM ==== */}
      {openForm && (
        <div className="passport__form">
          {/* ==== ISSUED BY ==== */}
          <div className="passport__form--group">
            <div className="passport__form--label">ISSUED BY</div>
            <input
              type="text"
              name="passport.issuedBy"
              className="passport__form--input passport__form--input--country"
              value={formik.values.passport.issuedBy}
              onChange={formik.handleChange}
              placeholder="COUNTRY"
              onClick={(e) => {
                e.stopPropagation();
                handleVisibleCountries(true);
              }}
              autoComplete="off"
            />

            {/* ====== COUNTRIES ===== */}
            {showCountries && (
              <div
                className={`passport__country--list ${
                  countriesFiltered.length <= 0
                    ? "passport__country--list--empty"
                    : ""
                }`}
              >
                {countriesFiltered.length > 0 ? (
                  <>
                    {countriesFiltered.map((country) => (
                      <div
                        className="passport__country--item"
                        key={country.id}
                        onClick={handleChangeIssueBy.bind(
                          null,
                          country.name,
                          country.two_char_code
                        )}
                      >
                        {country.name}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="passport__country--empty">No options</div>
                )}
              </div>
            )}
            {formik.errors.passport?.issuedBy && (
              <p className="passport__form--error">
                {formik.errors.passport.issuedBy}
              </p>
            )}
          </div>

          {/* ==== PASSPORT NUMBER ==== */}
          <div className="passport__form--group">
            <div className="passport__form--label">PASSPORT NUMBER</div>
            <input
              type="text"
              name="passport.number"
              value={formik.values.passport.number}
              onChange={formik.handleChange}
              className={`passport__form--input passport__form--input--number ${
                formik.errors.passport?.number
                  ? "passport__form--input--error"
                  : ""
              }`}
              placeholder="0000 0000 0000"
            />
            {formik.errors.passport?.number && (
              <p className="passport__form--error">
                {formik.errors.passport.number}
              </p>
            )}
          </div>

          {/* ==== EXPIRY DATE ==== */}
          <div className="passport__form--group">
            <div className="passport__form--label">EXPIRY DATE</div>
            <input
              type="text"
              name="passport.expireDate"
              value={formik.values.passport.expireDate}
              onChange={handleChangeExpireDate}
              className={`passport__form--input passport__form--input--date ${
                formik.errors.passport?.expireDate
                  ? "passport__form--input--error"
                  : ""
              }`}
              pattern={patternNumberKeyboard}
              placeholder="DD/MM/YYYY"
            />
            {formik.touched.passport?.expireDate &&
              formik.errors.passport?.expireDate && (
                <p className="passport__form--error">
                  {formik.errors.passport.expireDate}
                </p>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Passport;
