import React from "react";
import "./header.scss";
type Props = {};

const Header = (props: Props) => {
  return (
    <header className="header">
      <div className="header__container">
        <img src="/Header 2.png" alt="" className="header__logo" />
        <img src="/halftone-graphic.png" alt="" className="header__halftone" />
      </div>
    </header>
  );
};

export default Header;
