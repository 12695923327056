import { geocodeByPlaceId } from "react-google-places-autocomplete";

export const convertAddressGooglePlace = async (placeId: string) => {
  const [place] = await geocodeByPlaceId(placeId);

  const getAddressComponent = (type: string) =>
    place.address_components.find((c) => c.types.includes(type)) || {
      long_name: "",
      short_name: "",
    };

  const extractLongName = (type: string) =>
    getAddressComponent(type)?.long_name || "";
  const extractShortName = (type: string) =>
    getAddressComponent(type)?.short_name || "";

  const postcode = extractLongName("postal_code");
  const streetNumber = extractLongName("street_number");
  const route = extractLongName("route");
  const country = extractShortName("country");
  const state = extractShortName("administrative_area_level_1");
  const townCity = extractShortName("locality");

  return { country, postcode, streetNumber, route, state, townCity };
};
