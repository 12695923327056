import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import { RootState } from "@/redux/store";
import { useFormik } from "formik";
import * as yup from "yup";
import "../formEditInfo.scss";
import React from "react";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditFullName = React.memo(({ buttonsAction, onCloseModal }: Props) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is Required"),
    middleName: yup.string(),
    lastName: yup.string().required("Last Name is required"),
  });
  const formik = useFormik<{
    firstName: string;
    middleName: string;
    lastName: string;
  }>({
    initialValues: {
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
    },
    validationSchema,
    onSubmit(values) {
      dispatch(
        changeProfileInfo({
          ...profile,
          ...values,
        })
      );
      onCloseModal();
    },
  });
  return (
    <form className="form-edit" onSubmit={formik.handleSubmit}>
      <div className="form-edit__group">
        <label className="form-edit__label">FIRST NAME</label>
        <input
          type="text"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          className="form-edit__input"
        />
        {formik.errors.firstName && (
          <p className="form-edit__error--message">{formik.errors.firstName}</p>
        )}
      </div>
      <div className="form-edit__group">
        <label className="form-edit__label">MIDDLE NAME</label>
        <input
          type="text"
          name="middleName"
          value={formik.values.middleName}
          onChange={formik.handleChange}
          className="form-edit__input"
        />
        {formik.errors.middleName && (
          <p className="form-edit__error--message">
            {formik.errors.middleName}
          </p>
        )}
      </div>
      <div className="form-edit__group">
        <label className="form-edit__label">LAST NAME</label>
        <input
          type="text"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          className="form-edit__input"
        />
        {formik.errors.lastName && (
          <p className="form-edit__error--message">{formik.errors.lastName}</p>
        )}
      </div>

      {buttonsAction}
    </form>
  );
});

export default FormEditFullName;
