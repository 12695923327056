import { checkPhoneNumber } from "@/api/checkPhoneNumber";
import {
  convertAddressGooglePlace,
  formatAustralianPhoneNumber,
  validatePhoneNumberByRegion,
} from "@/shared/utils";
import { validateAge } from "@/shared/utils/validateAge";
import { validateDate } from "@/shared/utils/validateDate";
import { validateNationality } from "@/shared/utils/validateNationality";
import { nanoid } from "@reduxjs/toolkit";
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MonthPicker } from "react-dropdown-date";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import * as yup from "yup";
import iconFlagAud from "../../assets/images/Currency Flags/flag-for-australia_1f1e6-1f1fa.png";
import searchIcon from "../../assets/images/search-icon.png";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  IAddressProfile,
  IPayloadAboutYouForm,
} from "../../redux/profile/interface";
import { changeProfileInfo } from "../../redux/profile/profileSlice";
import { RootState } from "../../redux/store";
import { ICountry } from "../../shared/ts/type";
import ButtonBackStep from "../ButtonBackStep";
import ButtonNextStep from "../ButtonNextStep";
import { HeaderFormView } from "../HeaderFormView";
import "./aboutYou.scss";

type Props = {
  setView: React.Dispatch<React.SetStateAction<number>>;
  countries: ICountry[];
  setCountries: React.Dispatch<React.SetStateAction<ICountry[]>>;
};

const AboutYou = ({ setView, countries, setCountries }: Props) => {
  const dispatch = useAppDispatch();

  const { profile } = useAppSelector((state: RootState) => state.profile);

  const handleBackStep = useCallback(() => {
    setView(0);
  }, [setView]);
  const optionsGender: { id: string; value: string }[] = [
    {
      id: nanoid(),
      value: "Male",
    },
    {
      id: nanoid(),
      value: "Female",
    },
    {
      id: nanoid(),
      value: "Other",
    },
  ];
  const [yearValue, setYearValue] = useState<number>(0);
  const [monthValue, setMonthValue] = useState<number>(-1);
  const [dayValue, setDayValue] = useState<number>(0);
  const [phoneFormatted, setPhoneFormatted] = useState<string>("");
  const [searchValueAddress, setSearchValueAddress] = useState<string>("");
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [nationalCode, setNationalCode] = useState<string>("");
  const [errorsPhoneNumber, setErrorsPhoneNumber] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showListAddress, setShowListAddress] = useState<boolean>(false);

  const formSchema = yup.object({
    gender: yup.string().required("Please select your gender"),
    dateOfBirth: yup.string().required("Please enter your date of birth"),
    mobileNumber: yup.string().required("Please enter a valid number number"),
    nationality: yup.string().required("Please enter your nationality"),
    address: yup.string().required("Please enter your home address"),
  });

  const formik = useFormik<IPayloadAboutYouForm>({
    initialValues: {
      gender: "",
      dateOfBirth: "",
      mobileNumber: "",
      address: "",
      addressState: {
        addressLine: "",
        townCity: "",
        state: "",
        country: "",
        postcode: "",
      },
      nationality: "",
    },
    validationSchema: formSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setFieldError, setErrors }) => {
      try {
        if (!profile) return;
        setErrorsPhoneNumber([]);
        // * Validate age
        const isValidAge = validateDate(values.dateOfBirth);
        if (!isValidAge) {
          setFieldError("dateOfBirth", "Oops! Your date of birth invalid");
          return;
        }
        const isAgeEnough = validateAge(
          values.dateOfBirth.split("-").join("/")
        );
        if (!isAgeEnough) {
          setFieldError("dateOfBirth", "Oops! You need to at least 18 to join");
          return;
        }

        // * Validate phone number
        const isPhoneValid = validatePhoneNumberByRegion(
          values.mobileNumber,
          "AU"
        );
        if (!isPhoneValid) {
          setFieldError("mobileNumber", "Please enter a valid number number");
          return;
        }
        // * Check nationality
        const isValidNationality = validateNationality(
          countries,
          values.nationality
        );
        if (!isValidNationality) {
          setFieldError("nationality", "Please select a valid nationality");
          return;
        }
        setLoading(true);

        // * Check phone number
        await checkPhoneNumber(values.mobileNumber);

        dispatch(
          changeProfileInfo({
            ...profile,
            ...values,
            nationalityCode: nationalCode,
          })
        );
        setView(2);
        setErrorsPhoneNumber([]);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setErrorsPhoneNumber(error.response.data.error.mobile);
      }
    },
  });

  const handleVisibleCountries = useCallback((show: boolean) => {
    setShowCountries(show);
  }, []);

  useEffect(() => {
    window.addEventListener("click", () => handleVisibleCountries(false));
    return () => {
      window.removeEventListener("click", () => handleVisibleCountries(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      const {
        address,
        gender,
        dateOfBirth,
        mobileNumber,
        nationality,
        addressState,
        nationalityCode,
      } = profile;
      formik.setValues({
        gender: gender,
        dateOfBirth: dateOfBirth,
        address: address,
        mobileNumber: mobileNumber,
        nationality: nationality,
        addressState,
      });

      if (dateOfBirth) {
        setYearValue(Number(dateOfBirth.split("-")[0]));
        setMonthValue(Number(dateOfBirth.split("-")[1]) - 1);
        setDayValue(Number(dateOfBirth.split("-")[2]));
      }
      if (mobileNumber) {
        const newValue = formatAustralianPhoneNumber(mobileNumber, true);
        setPhoneFormatted(newValue);
      }
      if (address) {
        setSearchValueAddress(address);
      }

      if (nationalityCode) {
        setNationalCode(nationalityCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const formatDate = (date: string) => {
      formik.setFieldValue("dateOfBirth", date);
    };
    if (monthValue && dayValue && yearValue) {
      let month = String(Number(monthValue) + 1);
      let day = String(dayValue);

      if (String(month).length < 2) month = "0" + month;
      if (String(day).length < 2) day = "0" + day;

      formatDate(`${yearValue}-${month}-${day}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthValue, dayValue, yearValue]);

  const handleChangeGender = useCallback(
    (gender: string) => {
      formik.setFieldValue("gender", gender);
    },
    [formik]
  );

  const handleChangeMobileNumber = useCallback(
    (value: string) => {
      const formattedNumber = formatAustralianPhoneNumber(value);
      setPhoneFormatted(formattedNumber);

      if (value.startsWith("+")) {
        const newValue = formattedNumber.split("+")[1];
        if (newValue) {
          formik.setFieldValue("mobileNumber", newValue.split(" ").join(""));
        } else {
          formik.setFieldValue("mobileNumber", value);
        }
        return;
      }

      formik.setFieldValue("mobileNumber", formattedNumber.split(" ").join(""));
    },
    [formik]
  );

  const handleSelectAddress = useCallback(
    (address: string) => {
      formik.setFieldValue("address", address);
      setSearchValueAddress(address);
    },
    [formik]
  );

  const handleChangeNationalCode = useCallback(
    (name: string, code: string) => {
      formik.setFieldValue("nationality", name);
      setNationalCode(code);
    },
    [formik]
  );

  const countriesFiltered = useMemo(() => {
    return countries.filter((country) =>
      country.name
        .toLowerCase()
        .includes(formik.values.nationality.toLowerCase())
    );
  }, [formik.values.nationality, countries]);

  const handleConvertAddress = async (placeId: string) => {
    const { country, postcode, route, state, streetNumber, townCity } =
      await convertAddressGooglePlace(placeId);
    const addressStateValue: IAddressProfile = {
      addressLine: `${streetNumber} ${route}`,
      country,
      state,
      townCity,
      postcode,
    };
    formik.setFieldValue("addressState", addressStateValue);
  };

  const maxYear = new Date().getFullYear();
  const minYear = 1800;
  const handleChangeDayValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);
      if (value > 31) {
        return;
      }
      setDayValue(value);
    },
    []
  );

  const handleChangeYearValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);
      if (value > maxYear) return;
      setYearValue(value);
    },
    [maxYear]
  );

  return (
    <div className="about-you">
      <div className="about-you__container">
        <ButtonBackStep
          onClick={handleBackStep}
          className="about-you__button--back"
        />
        <HeaderFormView
          title="TELL US MORE"
          subtitle="This info helps us confirm your ID and setup your TravelPay Money Card."
        />
        <form className="about-you__form" onSubmit={formik.handleSubmit} noValidate>
          {/*======= GENDER ====== */}
          <div className="about-you__form--group fit-content">
            <label className="about-you__form--label">GENDER</label>
            <div className="about-you__form--select">
              {optionsGender.map((option) => (
                <div
                  key={option.id}
                  className={`about-you__form--option ${
                    formik.values.gender === option.value
                      ? "about-you__form--option--active"
                      : ""
                  }`}
                  onClick={handleChangeGender.bind(null, option.value)}
                >
                  {option.value}
                </div>
              ))}
            </div>
            {formik.touched.gender && formik.errors.gender && (
              <p className="about-you__form--error">{formik.errors.gender}</p>
            )}
          </div>

          {/*======= DAY OF BIRTH ====== */}
          <div className="about-you__form--group fit-content">
            <label className="about-you__form--label">DATE OF BIRTH</label>
            <div className="about-you__form--date">
              <input
                type="number"
                value={dayValue > 0 ? dayValue : ""}
                onChange={handleChangeDayValue}
                className="about-you__form--date--day"
                placeholder="DAY"
                min={1}
                max={31}
                pattern="[0-9]*"
              />
              <MonthPicker
                defaultValue="MONTH"
                caps
                endYearGiven
                year={yearValue}
                value={monthValue}
                onChange={(month: number) => {
                  setMonthValue(month);
                }}
                id={"month"}
                name={"month"}
                classes={clsx(
                  "about-you__form--date--month",
                  monthValue <= -1 ? "gray-color" : ""
                )}
                optionClasses={"option classes"}
              />

              <input
                type="number"
                value={yearValue > 0 ? yearValue : ""}
                onChange={handleChangeYearValue}
                className="about-you__form--date--year"
                placeholder="YEAR"
                min={minYear}
                max={maxYear}
                pattern="[0-9]*"
              />
            </div>

            {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
              <p className="about-you__form--error">
                {formik.errors.dateOfBirth}
              </p>
            )}
          </div>

          {/*======= MOBILE NUMBER ====== */}
          <div className="about-you__form--group">
            <div className="about-you__form--label--phone">
              <label className="about-you__form--label">MOBILE NUMBER</label>
              <div className="about-you__form--helper">
                <img
                  src={iconFlagAud}
                  alt="icon-flag"
                  className="about-you__form--helper--img"
                />
                <p className="about-you__form--helper--text">
                  Australian only*
                </p>
              </div>
            </div>
            <input
              type="text"
              className="about-you__form--input"
              value={phoneFormatted}
              onChange={(e) => handleChangeMobileNumber(e.target.value)}
              pattern="[0-9]*"
              formNoValidate={false}
              inputMode="numeric"
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <p className="about-you__form--error about-you__form--error--end">
                {formik.errors.mobileNumber}
              </p>
            )}
            {errorsPhoneNumber?.length > 0 && (
              <>
                {errorsPhoneNumber.map((err, index) => (
                  <p
                    className="about-you__form--error about-you__form--error--end"
                    key={index}
                  >
                    {err}
                  </p>
                ))}
              </>
            )}
          </div>

          {/*======= NATIONALITY ====== */}
          <div className="about-you__form--group">
            <label className="about-you__form--label">NATIONALITY</label>
            <div className="about-you__form--input--group">
              <input
                type="text"
                name="nationality"
                className="about-you__form--input"
                value={formik.values.nationality}
                onChange={formik.handleChange}
                onClick={(e) => {
                  e.stopPropagation();
                  handleVisibleCountries(true);
                }}
                autoComplete="off"
              />
              <img
                src={searchIcon}
                alt="search-icon"
                className="about-you__form--input--icon"
              />

              {/* ====== COUNTRIES ===== */}
              {showCountries && (
                <div
                  className={`about-you__country--list ${
                    countriesFiltered.length <= 0
                      ? "about-you__country--list--empty"
                      : ""
                  }`}
                >
                  {countriesFiltered.length > 0 ? (
                    <>
                      {countriesFiltered.map((country) => (
                        <div
                          className="about-you__country--item"
                          key={country.id}
                          onClick={handleChangeNationalCode.bind(
                            null,
                            country.name,
                            country.two_char_code.toLowerCase()
                          )}
                        >
                          {country.name}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="about-you__country--empty">No options</div>
                  )}
                </div>
              )}
            </div>
            {formik.touched.nationality && formik.errors.nationality && (
              <p className="about-you__form--error about-you__form--error--end">
                {formik.errors.nationality}
              </p>
            )}
          </div>

          {/*======= HOME ADDRESS ====== */}
          <div className="about-you__form--group">
            <label className="about-you__form--label">HOME ADDRESS</label>
            <div className="about-you__form--group-address">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  onChange(e) {
                    handleSelectAddress(e?.value.description);
                    handleConvertAddress(e?.value.place_id);
                  },
                  inputValue: searchValueAddress,
                  onInputChange(newValue, actionMeta) {
                    switch (actionMeta.action) {
                      case "input-change":
                        setSearchValueAddress(newValue);
                        break;
                    }
                    setShowListAddress(true);
                  },
                  value: {
                    label: searchValueAddress,
                    value: {
                      description: searchValueAddress,
                    },
                  },
                  classNamePrefix: `about-you__form--select-address`,
                  classNames: {
                    menu() {
                      return `about-you__form--select--menu" ${
                        !showListAddress ? "hidden" : ""
                      }`;
                    },
                  },
                  onFocus() {
                    setShowListAddress(false);
                  },
                  defaultMenuIsOpen: false,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [`au`],
                  },
                }}
              />
            </div>
            {formik.touched.address && formik.errors.address && (
              <p className="about-you__form--error about-you__form--error--end">
                {formik.errors.address}
              </p>
            )}
          </div>

          <ButtonNextStep
            className="about-you__form--btn--submit"
            title={"NEXT"}
            disabled={loading}
          />
        </form>
      </div>
    </div>
  );
};

export default AboutYou;
