import { Form } from "antd";
import { HeaderFormView } from "../HeaderFormView";

import { useFormik } from "formik";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { checkEmail } from "../../api/checkEmail";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { changeProfileInfo } from "../../redux/profile/profileSlice";
import { RootState } from "../../redux/store";
import ButtonNextStep from "../ButtonNextStep";
import "./StartedForm.scss";

type Props = {
  setView: React.Dispatch<React.SetStateAction<any>>;
};
function StartedForm({ setView }: Props) {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  const [loading, setLoading] = useState<boolean>(false);

  const emailRegex =
    /^[^\s@]+@[^\s@]+\.(com|net|org|edu|gov|mil|int|biz|info|name|mobi|aero|jobs|museum|travel|pro|coop|arpa|[a-z]{2})$/i;

  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter your first name"),
    middleName: yup.string(),
    lastName: yup.string().required("Please enter your last name"),
    email: yup
      .string()
      .matches(emailRegex, "Invalid format")
      .required("Please enter your email address"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      email: profile.email,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    async onSubmit(values, { setFieldError }) {
      try {
        setLoading(true);
        const { email, firstName, lastName, middleName } = values;
        await checkEmail(email);
        dispatch(
          changeProfileInfo({
            ...profile,
            email,
            firstName,
            lastName,
            middleName,
          })
        );
        setView(1);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setFieldError(
          "email",
          error.response?.data?.error?.email[0] ?? "Email invalid"
        );
      }
    },
  });

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
      const value = e.target.value;

      const containsNumber = /[0-9]/.test(value);

      if (containsNumber) {
        return;
      }

      formik.setFieldValue(fieldName, value);
    },
    [formik]
  );

  return (
    <div className="started-form-wrap">
      <div className="started-form-header">
        <HeaderFormView
          title="LET'S GET STARTED"
          subtitle="Please enter your name exactly as it is on your ID"
        />
      </div>
      <Form
        name="basic"
        layout="vertical"
        autoComplete="off"
        className="started-form"
        onSubmitCapture={formik.handleSubmit}
      >
        <div className="started-form__group">
          <label className="started-form__label">FIRST NAME</label>
          <input
            name="firstName"
            value={formik.values.firstName}
            onChange={(e) => handleChangeValue(e, "firstName")}
            className={`started-form__input
               ${formik.errors.firstName ? "started-form__input--error" : ""}`}
          />
          {formik.errors.firstName && (
            <span className="started-form__error--message">
              {formik.errors.firstName}
            </span>
          )}
        </div>

        <div className="started-form__group">
          <label className="started-form__label">MIDDLE NAME</label>
          <input
            name="middleName"
            value={formik.values.middleName}
            onChange={(e) => handleChangeValue(e, "middleName")}
            className={`started-form__input ${
              formik.errors.middleName ? "started-form__input--error" : ""
            }`}
          />
          {formik.errors.middleName && (
            <span className="started-form__error--message">
              {formik.errors.middleName}
            </span>
          )}
        </div>

        <div className="started-form__group">
          <label className="started-form__label">LAST NAME</label>

          <input
            name="lastName"
            value={formik.values.lastName}
            onChange={(e) => handleChangeValue(e, "lastName")}
            className={`started-form__input ${
              formik.errors.lastName ? "started-form__input--error" : ""
            }`}
          />
          {formik.errors.lastName && (
            <span className="started-form__error--message">
              {formik.errors.lastName}
            </span>
          )}
        </div>

        <div className="started-form__group">
          <label className="started-form__label">EMAIL</label>

          <input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className={`started-form__input ${
              formik.errors.email ? "started-form__input--error" : ""
            }`}
          />
          {formik.errors.email && (
            <span className="started-form__error--message">
              {formik.errors.email}
            </span>
          )}
        </div>
        <div className="registration-section">
          <ButtonNextStep title="LET'S GO" disabled={loading} />
          <div className="registration-terms">
            <span>
              {" "}
              By registering, you accept our{" "}
              <a
                href="https://pelikin.co/termsandconditions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                T&Cs
              </a>{" "}
              and{" "}
              <a
                href="https://pelikin.co/privacypolicy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
            </span>
          </div>
        </div>
      </Form>
    </div>
  );
}
export default StartedForm;
