import React, { useEffect, useMemo, useState } from "react";
import "./kycLastScreen.scss";
import "./pending.scss";
import "./success.scss";
import { Link, useSearchParams } from "react-router-dom";
import { searchParamsKeys } from "@/shared/constants";
import { RootState } from "@/redux/store";
import { useAppSelector } from "@/redux/hook";
import { EStatusKYCOnboarding } from "@/shared/ts/type";
import BannerSuccess from "@/assets/images/last-screen-success.jpg";
import BannerPending from "@/assets/images/last-screen-pending.jpg";
import BannerError from "@/assets/images/last-screen-error.jpg";
import CompleteSetup from "@/assets/images/completeSetup.png";
import DownloadAppImg from "@/assets/images/download-app.png";
import TPMCardAndApp from "@/assets/images/TPM card and app.png";
import EmailText from "@/assets/images/email icon.png";
import { getOTPCodeApi } from "@/api/getOTPCode";

interface ErrorData {
  error: string;
}

type Props = {};

const KycLastScreen = (props: Props) => {
  const [searchParams] = useSearchParams();
  const statusKYC = searchParams.get(searchParamsKeys.kycStatus);
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const [tpmCode, setTpmCode] = useState<string>("12QFAC");

  const isSuccessKYC = useMemo(() => {
    return statusKYC && statusKYC === EStatusKYCOnboarding.Success;
  }, [statusKYC]);

  const isPendingKYC = useMemo(() => {
    return statusKYC && statusKYC === EStatusKYCOnboarding.Pending;
  }, [statusKYC]);

  const bannerUrl = useMemo(() => {
    switch (statusKYC) {
      case EStatusKYCOnboarding.Success:
        return BannerSuccess;
      case EStatusKYCOnboarding.Pending:
        return BannerPending;
      default:
        return BannerError;
    }
  }, [statusKYC]);

  useEffect(() => {
    const getTPMCode = async () => {
      try {
        const data = await getOTPCodeApi(profile.email);
        setTpmCode(data.code);
      } catch (error) {
        setTpmCode("12BC7Q2");
      }
    };
    if (profile.email && (isSuccessKYC || isPendingKYC)) {
      getTPMCode();
    }
  }, [profile.email, isSuccessKYC, isPendingKYC]);

  const TitleSection: React.FC = () => {
    const errorDataString: string | null = localStorage.getItem("error");

    let data: ErrorData | null = null;
    if (errorDataString) {
      try {
        data = JSON.parse(errorDataString) as ErrorData;
      } catch (e) {
        console.error("Failed to parse error data from localStorage", e);
      }
    }
    const numberRef = data?.error;

    if (isSuccessKYC) {
      return (
        <p className="last-screen__title--success">
          Welcome to <span>TravelPay Money</span>, powered by{" "}
          <span>Pelikin!</span> <br /> We're excited to have you on board. To
          get started, simply download the Pelikin app to complete the setup of
          your travel money card. <br /> We've sent you an email and SMS with
          the next steps, but you can also find them below:
        </p>
      );
    }

    if (isPendingKYC) {
      return (
        <p className="last-screen__title--pending">
          We werent able to electronically verify your identiy so we’ll need to
          ask you for some additional information. <br /> Please download the
          Pelikin app to provide this and complete your account creation.
        </p>
      );
    }

    return (
      <p className="last-screen__title--fail">
        We're sorry, but we're currently unable to set up your TravelPay Money
        Card. Our team is actively investigating the issue, <br /> and we'll
        reach out to you shortly with an update.
        <span>
          If you have any questions or need assistance, please don't hesitate to
          contact us at{" "}
          <Link
            to="mailto:help@pelikinmoney.com."
            className="last-screen__title--fail--mail"
          >
            help@pelikinmoney.com.
          </Link>
        </span>
        <span>
          To help us assist you faster, please include the following reference
          number in your email:
        </span>
        <span>Reference Number: {numberRef}</span>
        <span>
          We apologise for any inconvenience this may have caused and appreciate
          your patience.
        </span>
      </p>
    );
  };

  const RenderFooter: React.FC = () => {
    return isPendingKYC ? (
      <div className="steps-container-pending">
        <div className="step">
          <div className="step-number">1</div>
          <h3>Download Pelikin app</h3>
          <div className="step-content">
            <div className="app-store-buttons">
              <img src={DownloadAppImg} alt="download-app" />
            </div>
          </div>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <h3>Enter Your Email Address</h3>
          <span className="step__title">
            Make sure to use your registered email address!
          </span>
          <div className="email-input-container">
            <div className="email-view">
              <img src={EmailText} className="email-icon" alt="email icon" />
            </div>
          </div>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <h3>Enter your TPM Code</h3>
          <span className="step__title-tpm">Your TPM code is:</span>
          <div className="step-content">
            <div className="tpm-code">{tpmCode}</div>
          </div>
        </div>
        <div className="step">
          <div className="step-number">4</div>
          <h3>Complete Account Setup</h3>
          <div className="step-content">
            <img
              src={CompleteSetup}
              alt="Phone and Card"
              className="step-content__image-complete"
            />
          </div>
        </div>
        <div className="step_5">
          <div className="step-number">5</div>
          <h3>Travel light, pay smart!</h3>
          <div className="step-content">
            <img
              src={TPMCardAndApp}
              alt="Phone and Card"
              className="step-content__image-4"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="steps-container">
        <div className="step">
          <div className="step-number">1</div>
          <h3>Download Pelikin app</h3>
          <div className="step-content">
            <div className="app-store-buttons">
              <img src={DownloadAppImg} alt="download-app" />
            </div>
          </div>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <h3>Enter Your Email Address</h3>
          <span className="step__title">
            Make sure to use your registered email address!
          </span>
          <div className="email-input-container">
            <div className="email-view">
              <img src={EmailText} className="email-icon" alt="email icon" />
            </div>
          </div>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <h3>Enter your TPM Code</h3>
          <span className="step__title-tpm">Your TPM code is:</span>
          <div className="step-content">
            <div className="tpm-code">{tpmCode}</div>
          </div>
        </div>
        <div className="step_5">
          <div className="step-number">4</div>
          <h3>Travel light, pay smart!</h3>
          <div className="step-content">
            <img
              src={TPMCardAndApp}
              alt="Phone and Card"
              className="step-content__image-4"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="last-screen">
      <img
        src={bannerUrl}
        className="last-screen__banner"
        alt="banner-last-screen"
      />
      <TitleSection />
      {(isSuccessKYC || isPendingKYC) && <RenderFooter />}
    </div>
  );
};

export default KycLastScreen;
