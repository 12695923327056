import {
  EComplianceStatus,
  EKycStatus,
  IResponseOnBoardingKyc,
} from "@/api/interface";
import { IPayloadVerifyIdentity } from "@/api/interface/verifyIdentity.interface";
import { verifyIdentity } from "@/api/verifyIdentity";
import { useAppSelector } from "@/redux/hook";
import { IIdentity } from "@/redux/profile/interface";
import { RootState } from "@/redux/store";
import { searchParamsKeys } from "@/shared/constants";
import { regexMedicareNumber } from "@/shared/regex";
import { EStatusKYCOnboarding } from "@/shared/ts/type";
import { convertPhoneNumber, isValidDate } from "@/shared/utils";
import { validateExpireDate } from "@/shared/utils/validateExpireDate";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as yup from "yup";
import ButtonBackStep from "../ButtonBackStep";
import ButtonNextStep from "../ButtonNextStep";
import { HeaderFormView } from "../HeaderFormView";
import Loading from "../Loading";
import DriverLicense from "./components/DriverLicense";
import Medicare from "./components/Medicare";
import Passport from "./components/Passport";
import "./verifyIdentity.scss";

type Props = {
  setView: React.Dispatch<React.SetStateAction<number>>;
};

const VerifyIdentity = React.memo(({ setView }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { partnerId } = useParams();
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [agreePolicy, setAgreePolicy] = useState<boolean>(false);

  useEffect(() => {
    const { identificationDoc } = profile;
    formik.setValues({
      ...identificationDoc,
    });
  }, []);

  const hasData = useCallback((obj: { [key: string]: any }) => {
    const keys = Object.keys(obj);
    let count = 0;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const subObj = obj[key];
      const hasData = Object.values(subObj).every((value: any) => {
        if (obj["driverLicense"].number) {
          return value !== undefined && value !== "" && value.authority !== "";
        }
        return value !== undefined && value !== "";
      });

      if (hasData) {
        count++;
        if (count === 2) {
          return true;
        }
      }
    }

    return false;
  }, []);

  const isObjectFullyFilled = useCallback((obj: { [key: string]: string }) => {
    for (const key in obj) {
      const _obj = obj[key] as any;
      if (
        obj.hasOwnProperty(key) &&
        (_obj === null ||
          _obj === undefined ||
          _obj === "" ||
          _obj.authority === "")
      ) {
        return false;
      }
    }
    return true;
  }, []);
  const handleNextStep = useCallback(() => {
    setView((prev) => prev + 1);
  }, [setView]);

  const validateEachField = useCallback(
    (values: IIdentity) => {
      const { driverLicense, medicare, passport } = values;
      if (
        !driverLicense.expireDate &&
        !driverLicense.number &&
        !driverLicense.stateIssued.authority &&
        !medicare.expireDate &&
        !medicare.referenceNumber &&
        !medicare.number &&
        !passport.expireDate &&
        !passport.issuedBy &&
        !passport.issuedCode &&
        !passport.number
      ) {
        const isEnoughData = hasData(
          values as unknown as { [key: string]: {} }
        );
        return isEnoughData;
      }
      return true;
    },
    [hasData]
  );

  const validateDriverLicense = useCallback((values: IIdentity) => {
    const { driverLicense } = values;
    if (
      !driverLicense.number &&
      !driverLicense.stateIssued.authority &&
      !driverLicense.expireDate
    ) {
      formik.setFieldError("driverLicense.number", "Required");
      formik.setFieldError("driverLicense.stateIssued.authority", "Required");
      formik.setFieldError("driverLicense.expireDate", "Required");
      return false;
    }

    if (!driverLicense.number) {
      formik.setFieldError("driverLicense.number", "Required");
      return false;
    }
    if (!driverLicense.stateIssued.authority) {
      formik.setFieldError("driverLicense.stateIssued.authority", "Required");
      return false;
    }

    if (!driverLicense.expireDate) {
      formik.setFieldError("driverLicense.expireDate", "Required");
      return false;
    }

    const isValidExpire = validateExpireDate(driverLicense.expireDate);
    if (!isValidExpire) {
      formik.setFieldError("driverLicense.expireDate", "Date is expired");
      return false;
    }
    return true;
  }, []);

  const validatePassport = useCallback((values: IIdentity) => {
    const { passport } = values;
    if (!passport.issuedBy && !passport.number && !passport.expireDate) {
      formik.setFieldError("passport.number", "Required");
      formik.setFieldError("passport.expireDate", "Required");
      formik.setFieldError("passport.issuedBy", "Required");
      return false;
    }

    if (!passport.issuedBy) {
      formik.setFieldError("passport.issuedBy", "Required");
      return false;
    }
    if (!passport.number) {
      formik.setFieldError("passport.number", "Required");
      return false;
    }

    if (!passport.expireDate) {
      formik.setFieldError("passport.expireDate", "Required");
      return false;
    }

    const isValidExpire = validateExpireDate(passport.expireDate);
    if (!isValidExpire) {
      formik.setFieldError("passport.expireDate", "Date is expired");
      return false;
    }
    return true;
  }, []);

  const validateMedicare = useCallback((values: IIdentity) => {
    const { medicare } = values;
    if (!medicare.number && !medicare.referenceNumber && !medicare.expireDate) {
      formik.setFieldError("medicare.number", "Required");
      formik.setFieldError("medicare.referenceNumber", "Required");
      formik.setFieldError("medicare.expireDate", "Required");
      return false;
    }
    if (!medicare.number) {
      formik.setFieldError("medicare.number", "Required");
      return false;
    }
    if (!medicare.referenceNumber) {
      formik.setFieldError("medicare.referenceNumber", "Required");
      return false;
    }

    if (!medicare.expireDate) {
      formik.setFieldError("medicare.expireDate", "Required");
      return false;
    } else {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      console.log(
        "values.medicare.expireDate",
        values.medicare.expireDate.split("/").reverse().join()
      );
      const expireDate = new Date(
        `${values.medicare.expireDate.split("/").reverse().join("-")}`
      );
      if (expireDate.getTime() < date.getTime()) {
        formik.setFieldError("medicare.expireDate", "Date is expired");
        return;
      }
    }
    return true;
  }, []);

  const validationSchema = yup.object({
    driverLicense: yup.object({
      number: yup.string(),
      stateIssued: yup.object({
        authority: yup.string(),
        shortname: yup.string(),
      }),
      expireDate: yup
        .string()
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
          "Date invalid"
        )
        .test("is-valid-date", "Date is invalid", (value?: string) =>
          isValidDate(value!)
        ),
    }),

    passport: yup.object({
      number: yup.string(),
      issuedBy: yup.string(),
      expireDate: yup
        .string()
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
          "Date invalid"
        )
        .test("is-valid-date", "Date is invalid", (value?: string) =>
          isValidDate(value!)
        ),
    }),
    medicare: yup.object({
      number: yup.string().matches(regexMedicareNumber, "Number invalid"),
      referenceNumber: yup
        .number()
        .min(1, "Number invalid")
        .max(9, "Number invalid"),
      expireDate: yup.string().min(7, "Expiry date invalid"),
    }),
  });
  const formik = useFormik<IIdentity>({
    initialValues: {
      driverLicense: {
        number: undefined,
        stateIssued: {
          authority: "",
          shortname: "",
        },
        expireDate: "",
      },
      passport: {
        number: "",
        issuedBy: "",
        expireDate: "",
        issuedCode: "",
      },
      medicare: {
        number: "",
        referenceNumber: undefined,
        expireDate: "",
      },
    },
    validationSchema,
    async onSubmit(values, formikHelpers) {
      setError("");
      // * Validate 2 of 3 types ID
      const isValid = validateEachField(values);
      if (!isValid) {
        setError("Please provide at least 2 of the below ID types");
        return;
      }

      // * Validate Driver license
      const isValidDriverLicense = validateDriverLicense(values);
      // * Validate Passport
      const isValidPassport = validatePassport(values);
      const isValidMedicare = validateMedicare(values);
      const validationResults = [
        isValidDriverLicense,
        isValidPassport,
        isValidMedicare,
      ];

      const validCount = validationResults.filter(Boolean).length;
      if (validCount < 2) {
        return;
      }
      formik.setErrors({});

      if (!agreePolicy) {
        setError("Please agree and tick the box to continue");
        return;
      }
      setError("");

      try {
        const {
          address,
          dateOfBirth,
          email,
          gender,
          firstName,
          lastName,
          mobileNumber,
          middleName,
          nationalityCode,
          addressState,
        } = profile;
        const { driverLicense, medicare, passport } = values;

        // **** Validate Data Ids
        const isHasMedicare = isObjectFullyFilled(
          medicare as unknown as { [key: string]: string }
        );
        const isHasDriverLicense = isObjectFullyFilled(
          driverLicense as unknown as { [key: string]: string }
        );
        const isHasPassport = isObjectFullyFilled(
          passport as unknown as { [key: string]: string }
        );

        // **** Format Values
        const newPhone = convertPhoneNumber(mobileNumber);

        const holderName = `${firstName} ${middleName} ${lastName}`;
        const nationCode = nationalityCode.toUpperCase();

        const [day, month, year] = passport.expireDate.split("/");
        const formatPassportExpireDate = `${year}-${month}-${day}`;
        const payload: IPayloadVerifyIdentity = {
          is_tpm_user: 1,
          partnerId: partnerId ?? "partner1",
          firstName,
          lastName,
          middleName,
          dob: dateOfBirth,
          email,
          gender,
          mobileNumber: newPhone,
          nationality: nationCode,
          address: {
            ADDRESS_LINE: addressState.addressLine,
            TOWN_CITY: addressState.townCity,
            STATE: addressState.state,
            COUNTRY: addressState.country,
            POSTCODE: addressState.postcode,
          },
          postcode: addressState.postcode,
          state: addressState.state,
          addressCountry: nationCode,
          countryCode: nationCode,
          userNickName: holderName,
          identificationDoc: {
            ...(isHasDriverLicense && {
              DRIVING_LICENSE: {
                identificationType: "DRIVING_LICENSE",
                identificationIssuingAuthority:
                  driverLicense.stateIssued.authority,
                identificationDocIssuanceState:
                  driverLicense.stateIssued.shortname,
                identificationValue: String(driverLicense.number),
                identificationDocReferenceNumber: String(driverLicense.number),
                identificationDocHolderName: holderName,
                identificationDocIssuanceCountry: "AU",
              },
            }),
            ...(isHasPassport && {
              PASSPORT: {
                identificationType: "PASSPORT",
                identificationDocIssuanceCountry: passport.issuedCode,
                identificationDocExpiry: formatPassportExpireDate,
                identificationValue: String(passport.number),
                identificationDocHolderName: holderName,
              },
            }),
            ...(isHasMedicare && {
              MEDICARE_CARD: {
                identificationType: "MEDICARE_CARD",
                identificationDocColor: "G",
                identificationValue: medicare.number,
                identificationDocHolderName: holderName,
                identificationDocReferenceNumber: String(
                  medicare.referenceNumber
                ),
                identificationDocExpiry: medicare.expireDate
                  .split("/")
                  .reverse()
                  .join("-"),
              },
            }),
          },
        };
        setLoading(true);

        const res: IResponseOnBoardingKyc = await verifyIdentity(payload);
        if (
          res &&
          res.kyc_status === EKycStatus.Failed &&
          res.compliance_status === EComplianceStatus.Reject
        ) {
          throw new Error("Account has been rejected");
        }
        if (res && res.compliance_status !== EComplianceStatus.Completed) {
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              [searchParamsKeys.kycStatus]: EStatusKYCOnboarding.Pending,
            }).toString(),
          });
        } else {
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              [searchParamsKeys.kycStatus]: EStatusKYCOnboarding.Success,
            }).toString(),
          });
        }

        handleNextStep();
        setLoading(false);
      } catch (error) {
        handleNextStep();
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            [searchParamsKeys.kycStatus]: EStatusKYCOnboarding.Fail,
          }).toString(),
        });
        setLoading(false);
      }
    },
  });

  const handleChangeAgreePolicy = useCallback((e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setAgreePolicy(e.target.checked);
    if (checked) {
      setError("");
    }
  }, []);

  return (
    <div className="verify-identity">
      <div className="verify-identity__container">
        <ButtonBackStep
          onClick={setView.bind(null, (prev) => prev - 1)}
          className="about-you__button--back"
        />
        <HeaderFormView
          title="VERIFY YOUR IDENTITY"
          subtitle={
            <>
              Last step! Please provide at least 2 of the ID types below. <br />{" "}
              We don’t store this information, it’s only used to verify your
              identity.
            </>
          }
        />

        <form
          onSubmit={formik.handleSubmit}
          className="verify-identity__form"
          noValidate
        >
          <div className="verify-identity__form--content">
            <DriverLicense formik={formik} />

            <Passport formik={formik} />

            <Medicare formik={formik} />
          </div>
          <div className="verify-identity__form--action">
            <div className="verify-identity__agree--policy">
              <Checkbox
                indeterminate
                prefixCls="verify-identity__agree--policy--checkbox"
                checked={agreePolicy}
                onChange={handleChangeAgreePolicy}
              />
              <div className="verify-identity__agree--policy--text">
                I agree to let you use and share my personal info to verify who
                I am.
              </div>
            </div>

            <ButtonNextStep
              title="SUBMIT"
              className="verify-identity__form--btn--submit"
              disabled={loading}
            />

            <p className="verify-identity__error">{error}</p>
          </div>
        </form>
      </div>
      {loading && <Loading />}
    </div>
  );
});

export default VerifyIdentity;
