export interface IExchangeRatePrams {
  sourceCurrencyCode: string;
  destinationCurrencyCode: string;
  destinationAmount?: number;
  sourceAmount?: number;
}

export interface IExchangeRateResponse {
  destinationAmount: number;
  destinationCurrencyCode: string;
  markupAmount: number;
  rate: string;
  sourceAmount: number;
  sourceCurrencyCode: string;
  markupRate: number;
  netExchangeRate: number;
  exchangeRate: number;
}

export enum EKycStatus {
  Completed = "Completed",
  Pending = "Pending",
  Clear = "Clear",
  Failed = "Failed",
}

export enum EComplianceStatus {
  Completed = "COMPLETED",
  ActionRequired = "ACTION_REQUIRED",
  InProgress = "IN_PROGRESS",
  Reject = "REJECT",
}
export interface IResponseOnBoardingKyc {
  user_id: number;
  kyc_status: EKycStatus;
  kyc_mode: string;
  compliance_status: string;
}
