import { CurrencyCardProps } from "./type";
import "./CurrencyCard.scss";

function CurrencyCard(props: CurrencyCardProps) {
  const { currency, active, onClick } = props;
  return (
    <div
      className={`currency-card ${active ? "currency-card-active" : ""}`}
      onClick={onClick}
    >
      <img className="currency-card__emoji" src={currency.icon} alt="" />

      <div className="currency-card-content">
        <h5>{currency.currency_code}</h5>
        <span>{currency.name}</span>
      </div>
    </div>
  );
}
export default CurrencyCard;
