import { ICountry } from "../shared/ts/type";
import apiClient from "./apiClient";

export const countryApi = {
  async getAll(): Promise<ICountry[]> {
    try {
      const { data } = await apiClient.get("/countries");
      return data.body;
    } catch (error) {
      throw error;
    }
  },
};
