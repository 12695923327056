import apiClient from "./apiClient";

export const checkEmail = async (email: string) => {
  try {
    const { data } = await apiClient.post("/onboarding/check/email", { email });
    return data;
  } catch (error) {
    throw error;
  }
};
