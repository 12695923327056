import React from "react";
import "./modalEditInfo.scss";
import { Modal } from "antd";
import FormEditInfo from "../FormEditInfo";

export enum ETypeInfo {
  FullName = "FullName",
  Email = "Email",
  DateOfBirth = "DateOfBirth",
  MobileNumber = "MobileNumber",
  Nationality = "Nationality",
  Address = "Address",
}

type Props = {
  type: ETypeInfo;
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

const ModalEditInfo = ({ title, onClose, isOpen, type }: Props) => {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      centered
      closeIcon={false}
      footer={false}
      classNames={{
        wrapper: "modal-edit__wrapper",
        content: "modal-edit__content",
      }}
    >
      <h3 className="modal-edit__title">{title}</h3>
      <p className="modal-edit__subtitle">
        Double check everything is correct and hit save!
      </p>
      <FormEditInfo type={type} onCloseModal={onClose} />
    </Modal>
  );
};

export default ModalEditInfo;
