import apiClient from "./apiClient";

export const checkPhoneNumber = async (mobile: string) => {
  try {
    const newMobile = mobile.startsWith("61")
      ? mobile.replace(/61/g, "0")
      : mobile;
    const { data } = await apiClient.post("/onboarding/check/mobile", {
      mobile: newMobile,
    });
    if (data.error) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    throw error;
  }
};
