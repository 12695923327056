import React, { FC } from "react";
import { ETypeInfo } from "../ModalEditInfo/ModalEditInfo";
import FormEditFullName from "./FormEditFullName/FormEditFullName";
import "./formEditInfo.scss";
import FormEditEmail from "./FormEditEmail";
import FormEditDateOfBirth from "./FormEditDateOfBirth";
import FormEditMobileNumber from "./FormEditMobileNumber";
import FormEditNationality from "./FormEditNationality";
import FormEditAddress from "./FormEditAddress";
type Props = {
  type: ETypeInfo;
  onCloseModal: () => void;
};

const FormEditInfo = React.memo(({ type, onCloseModal }: Props) => {
  // * BUTTONS ACTION
  const ButtonsAction: FC = () => {
    return (
      <div className="form-edit__btn--list">
        <button
          type="submit"
          className="form-edit__btn--item form-edit__btn--item--save"
        >
          SAVE CHANGES
        </button>
        <button
          type="button"
          onClick={onCloseModal}
          className="form-edit__btn--item form-edit__btn--item--cancel"
        >
          CANCEL
        </button>
      </div>
    );
  };

  return (
    <div>
      {type === ETypeInfo.FullName && (
        <FormEditFullName
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}

      {type === ETypeInfo.Email && (
        <FormEditEmail
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}
      {type === ETypeInfo.DateOfBirth && (
        <FormEditDateOfBirth
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}
      {type === ETypeInfo.MobileNumber && (
        <FormEditMobileNumber
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}
      {type === ETypeInfo.Nationality && (
        <FormEditNationality
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}
      {type === ETypeInfo.Address && (
        <FormEditAddress
          buttonsAction={<ButtonsAction />}
          onCloseModal={onCloseModal}
        />
      )}
    </div>
  );
});

export default FormEditInfo;
