import apiClient from "./apiClient";
import { IResponseGetOTPCode } from "./interface";

export const getOTPCodeApi = async (
  email: string
): Promise<IResponseGetOTPCode> => {
  try {
    const { data } = await apiClient.post("/travelpay/tpmcode", { email });
    if (data.error) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    throw error;
  }
};
