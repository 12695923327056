import IconPassPort from "@/assets/images/TPM Icons-43.png";
import { IIdentity } from "@/redux/profile/interface";
import { FormikProps } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import IdentityPlaceholder from "../IdentityPlaceholder";
import "./medicare.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import {
  patternNumberKeyboard,
  regexAllowOnlyNumber,
  regexDigitMonth,
  regexFormatMonthYear,
  regexLeadZeroNumber,
} from "@/shared/regex";

type Props = {
  formik: FormikProps<IIdentity>;
};

const Passport = ({ formik }: Props) => {
  const [openForm, setOpenForm] = useState<boolean>(false);

  const handleVisibleForm = useCallback(() => {
    setOpenForm(!openForm);
  }, [openForm]);

  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.profile);

  useEffect(() => {
    const { medicare } = formik.values;

    if (medicare.number && medicare.expireDate && medicare.referenceNumber) {
      dispatch(
        changeProfileInfo({
          ...profile,
          identificationDoc: {
            ...profile.identificationDoc,
            medicare: {
              ...profile.identificationDoc.medicare,
              ...medicare,
            },
          },
        })
      );
    }
  }, [dispatch, formik.values]);

  const isFinishInfo = useMemo(() => {
    const { number, expireDate, referenceNumber } = formik.values.medicare;
    return Boolean(
      number &&
        expireDate &&
        referenceNumber &&
        !formik.errors.medicare?.expireDate
    );
  }, [formik]);

  const handleChangeReferenceNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (Number(value) >= 10) {
        formik.setFieldValue("medicare.referenceNumber", 1);
        return;
      }
      formik.setFieldValue("medicare.referenceNumber", value);
    },
    [formik]
  );

  function handleConvertExpireDate(string: string) {
    return string
      .replace(regexAllowOnlyNumber, "") // Allow only numbers
      .replace(regexDigitMonth, "0$1") // Handle single digit month
      .replace(regexLeadZeroNumber, "0") // Handle leading zeros
      .replace(regexFormatMonthYear, "$1/$2"); //Handle month/year format
  }

  const handleChangeExpireDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const newValue = handleConvertExpireDate(value);
    formik.setFieldValue("medicare.expireDate", newValue);
  };

  return (
    <div className={`medicare ${openForm ? "open" : ""}`}>
      <IdentityPlaceholder
        title="Medicare"
        visibleForm={openForm}
        handleVisibleForm={handleVisibleForm}
        isFinishInfo={isFinishInfo}
        icon={IconPassPort}
      />

      {/* ==== FORM ==== */}
      {openForm && (
        <div className="medicare__form">
          {/* ==== CARD NUMBER ==== */}
          <div className="medicare__form--content">
            <div className="medicare__form--group">
              <div className="medicare__form--label">CARD NUMBER</div>
              <input
                type="number"
                name="medicare.number"
                placeholder="0000 00000 0"
                value={formik.values.medicare.number}
                onChange={formik.handleChange}
                className={`medicare__form--input medicare__form--input--number ${
                  formik.errors.medicare?.number
                    ? "medicare__form--input--error"
                    : ""
                }`}
              />
              {formik.errors.medicare?.number && (
                <p className="medicare__form--error">
                  {formik.errors.medicare.number}
                </p>
              )}
            </div>

            {/* ==== REFERENCE CARD NUMBER ==== */}
            <div className="medicare__form--group">
              <div className="medicare__form--label medicare__form--label-wrap">
                REFERENCE <br /> CARD NUMBER
              </div>
              <div className="medicare__form--group--medicare--number">
                <input
                  type="number"
                  name="medicare.referenceNumber"
                  value={formik.values.medicare.referenceNumber}
                  onChange={handleChangeReferenceNumber}
                  className={`medicare__form--input medicare__form--input--reference--number ${
                    formik.errors.medicare?.referenceNumber
                      ? "medicare__form--input--error"
                      : ""
                  }`}
                  placeholder="0"
                  min={1}
                  max={10}
                  pattern={patternNumberKeyboard}
                />

                {formik.errors.medicare?.referenceNumber && (
                  <p className="medicare__form--error">
                    {formik.errors.medicare.referenceNumber}
                  </p>
                )}
              </div>
            </div>

            {/* ==== EXPIRY DATE ==== */}
            <div className="medicare__form--group">
              <div className="medicare__form--label">EXPIRY DATE</div>
              <input
                type="text"
                name="medicare.expireDate"
                value={formik.values.medicare.expireDate}
                onChange={handleChangeExpireDate}
                className={`medicare__form--input medicare__form--input--date ${
                  formik.errors.medicare?.expireDate
                    ? "medicare__form--input--error"
                    : ""
                }`}
                placeholder="MM/YYYY"
                pattern={patternNumberKeyboard}
              />

              {formik.errors.medicare?.expireDate && (
                <p className="medicare__form--error">
                  {formik.errors.medicare.expireDate}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Passport;
