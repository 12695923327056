import moment from "moment";

export const validateDate = (date: string) => {
  const dateFormat = moment(date, true);
  return dateFormat.isValid();
};


export const isValidDate = (dateString: string) => {
  if (!dateString) return true;
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day); // JavaScript months are 0-11

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};