import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CurrencyState } from "./type";

interface IInitialState {
  currencies: CurrencyState[];
  isShowRate: boolean;
  isShowRateDestination: boolean;
  indexesConverter: { [key: string]: string };
}
const initialState: IInitialState = {
  currencies: [],
  isShowRate: false,
  isShowRateDestination: false,
  indexesConverter: {},
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    addCurrency: (state, action: PayloadAction<CurrencyState>) => {
      const index = state.currencies.findIndex(
        (item) => item.sourceCurrencyCode === action.payload.sourceCurrencyCode
      );
      if (index !== -1) {
        state.currencies[index] = action.payload;
      } else {
        state.currencies = [...state.currencies, action.payload];
      }
    },
    removeCurrency: (
      state,
      action: PayloadAction<{
        sourceCurrencyCode: string;
        currencyCode: string;
      }>
    ) => {
      state.currencies = [...state.currencies].filter(
        (item) => item.sourceCurrencyCode !== action.payload.sourceCurrencyCode
      );
      state.indexesConverter = {
        ...state.indexesConverter,
        [action.payload.currencyCode]: "",
      };
    },
    updateIndexConverter: (
      state,
      action: PayloadAction<{
        currencyCode: string;
        position: "left" | "right";
      }>
    ) => {
      state.indexesConverter = {
        ...state.indexesConverter,
        [action.payload.currencyCode]: action.payload.position,
      };
    },
    renewCurrencyData(
      state,
      action: PayloadAction<{
        currencies: CurrencyState[];
        indexConverter: { [key: string]: string };
      }>
    ) {
      state.currencies = action.payload.currencies;
      state.indexesConverter = action.payload.indexConverter;
    },
  },
});

export const {
  addCurrency,
  removeCurrency,
  updateIndexConverter,
  renewCurrencyData,
} = currencySlice.actions;

export default currencySlice.reducer;
