import React, { useCallback, useMemo } from "react";
import "../formEditInfo.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { RootState } from "@/redux/store";
import * as yup from "yup";
import { useFormik } from "formik";
import { changeProfileInfo } from "@/redux/profile/profileSlice";
import { validateAge } from "@/shared/utils/validateAge";
import { patternNumberKeyboard } from "@/shared/regex";

type Props = {
  buttonsAction: React.ReactElement;
  onCloseModal: () => void;
};

const FormEditDateOfBirth = React.memo(
  ({ buttonsAction, onCloseModal }: Props) => {
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state: RootState) => state.profile);

    const validationSchema = yup.object({
      dateOfBirth: yup.string().required("Please enter your date of birth"),
    });

    const formatDateValue = useMemo(() => {
      if (!profile.dateOfBirth) return "";
      const [year, month, day] = profile.dateOfBirth.split("-");
      return `${month}/${day}/${year}`;
    }, [profile]);

    const formik = useFormik<{ dateOfBirth: string }>({
      initialValues: {
        dateOfBirth: formatDateValue,
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit(values, { setFieldError }) {
        const isValidAge = validateAge(values.dateOfBirth);
        if (!isValidAge) {
          setFieldError("dateOfBirth", "Oops! You need to at least 18 to join");
          return;
        }

        const [month, day, year] = values.dateOfBirth.split("/");
        dispatch(
          changeProfileInfo({
            ...profile,
            dateOfBirth: `${year}-${month}-${day}`,
          })
        );
        onCloseModal();
      },
    });

    const handleChangeExpireDate = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        const previousValue = formik.values.dateOfBirth || "";

        // Replace all non-numeric characters except slashes
        value = value.replace(/[^0-9/]/g, "");

        // Check if the user is deleting a character
        if (previousValue.length > value.length) {
          // Handle deletion near slashes
          if (previousValue.length === 4 && value.length === 3) {
            value = value.slice(0, 2);
          } else if (previousValue.length === 7 && value.length === 6) {
            value = value.slice(0, 5);
          }
        } else {
          // Add slashes for date format
          if (value.length > 2 && value[2] !== "/") {
            value = value.slice(0, 2) + "/" + value.slice(2);
          }
          if (value.length > 5 && value[5] !== "/") {
            value = value.slice(0, 5) + "/" + value.slice(5);
          }
        }

        formik.setFieldValue("dateOfBirth", value);
      },
      [formik]
    );
    return (
      <form className="form-edit" onSubmit={formik.handleSubmit} noValidate>
        <div className="form-edit__group">
          <label className="form-edit__label">DATE OF BIRTH</label>
          <input
            type="text"
            name="dateOfBirth"
            value={formik.values.dateOfBirth}
            onChange={handleChangeExpireDate}
            className="form-edit__input form-edit__input--date"
            onClick={(e) => e.preventDefault()}
            placeholder="MM/DD/YYYY"
            pattern={patternNumberKeyboard}
          />
          {formik.errors.dateOfBirth && (
            <p className="form-edit__error--message">
              {formik.errors.dateOfBirth}
            </p>
          )}
        </div>

        {buttonsAction}
      </form>
    );
  }
);

export default FormEditDateOfBirth;
