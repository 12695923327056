import { HeaderFormViewProps } from "./type";
import "./HeaderFormView.scss";

function HeaderFormView(props: HeaderFormViewProps) {
  const { title, subtitle } = props;

  return (
    <div className="header-form-view">
      <h3 className="title-header">{title}</h3>
      {subtitle && <span className="label-header">{subtitle}</span>}
    </div>
  );
}

export default HeaderFormView;
